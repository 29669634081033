<template>
  <div class="password-wrap">
    <div class="title">{{ !userInfo.hasPassword ? '修改密码' : '设置密码' }}</div>
    <div class="form-wrap">
      <el-form
        :model="form"
        ref="passwordFormRef"
        :rules="rules"
        inline-message
        label-position="right"
        label-width="175px"
      >
        <el-form-item v-if="!userInfo.hasPassword" label="原密码&nbsp;:" prop="oldpwd">
          <el-input
            size="small"
            type="password"
            v-model="form.oldpwd"
            placeholder="请输入原登录密码"
            show-password
          ></el-input>
        </el-form-item>
        <div class="form-item-verify">
          <el-form-item prop="newPwd" label="新密码&nbsp;:">
            <el-input
              v-model="form.newPwd"
              size="small"
              type="password"
              placeholder="请输入新的登录密码"
              show-password
              @focus="focusVerify('newPwdShow', true)"
              @blur="focusVerify('newPwdShow', false)"
            ></el-input>
          </el-form-item>
          <div class="form-verify-model" v-if="state.newPwdShow">
            <div class="triangle1"></div>
            <div class="triangle2"></div>
            <div class="verifry-content">
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwd,
                    'icon-cuowu': form.newPwd.length !== 0 && (form.newPwd.length > 18 || form.newPwd.length < 8),
                    'icon-zhengque': form.newPwd.length <= 18 && form.newPwd.length >= 8 && form.newPwd.length !== 0
                  }"
                ></i>
                <span>长度为8-18个字符</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwd,
                    'icon-cuowu': form.newPwd && form.newPwd.indexOf(' ') > -1,
                    'icon-zhengque': form.newPwd && form.newPwd.indexOf(' ') === -1
                  }"
                ></i>
                <span>不能使用空格、中文</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwd,
                    'icon-cuowu': form.newPwd && !checkPass(form.newPwd),
                    'icon-zhengque': form.newPwd && checkPass(form.newPwd)
                  }"
                ></i>
                <span>至少含数字/字母/字符2种组合</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwd,
                    'icon-cuowu': form.newPwd && illegal(form.newPwd),
                    'icon-zhengque': form.newPwd && !illegal(form.newPwd)
                  }"
                ></i>
                <span>不能含有非法字符</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item-verify">
          <el-form-item prop="newPwdCheck" label="确认密码&nbsp;:">
            <el-input
              v-model="form.newPwdCheck"
              size="small"
              type="password"
              placeholder="再次输入新的登录密码"
              show-password
              @focus="focusVerify('agentPwdShow', true)"
              @blur="focusVerify('agentPwdShow', false)"
            ></el-input>
          </el-form-item>
          <div class="form-verify-model" v-if="state.agentPwdShow">
            <div class="triangle1"></div>
            <div class="triangle2"></div>
            <div class="verifry-content">
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwdCheck,
                    'icon-cuowu':
                      form.newPwdCheck.length !== 0 && (form.newPwdCheck.length > 18 || form.newPwdCheck.length < 8),
                    'icon-zhengque':
                      form.newPwdCheck.length <= 18 && form.newPwdCheck.length >= 8 && form.newPwdCheck.length !== 0
                  }"
                ></i>
                <span>长度为8-18个字符</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwdCheck,
                    'icon-cuowu': form.newPwdCheck && form.newPwdCheck.indexOf(' ') > -1,
                    'icon-zhengque': form.newPwdCheck && form.newPwdCheck.indexOf(' ') === -1
                  }"
                ></i>
                <span>不能使用空格、中文</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwdCheck,
                    'icon-cuowu': form.newPwdCheck && !checkPass(form.newPwdCheck),
                    'icon-zhengque': form.newPwdCheck && checkPass(form.newPwdCheck)
                  }"
                ></i>
                <span>至少含数字/字母/字符2种组合</span>
              </div>
              <div class="verifry-item">
                <i
                  class="iconfont icons"
                  :class="{
                    'icon-tanhao': !form.newPwdCheck,
                    'icon-cuowu': form.newPwdCheck && illegal(form.newPwdCheck),
                    'icon-zhengque': form.newPwdCheck && !illegal(form.newPwdCheck)
                  }"
                ></i>
                <span>不能含有非法字符</span>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="actions-wrap">
      <el-button class="btn-save" size="medium" type="danger" :loading="form.loading" @click="handleSave(false)">
        确定
      </el-button>
      <el-button class="btn-cancel" size="medium" @click="handleCancel">取消</el-button>
    </div>
  </div>
</template>
<script>
import { reactive, onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { updatePassword } from '@/apis'
import { ElMessage } from 'element-plus'
import { useLogout } from '@/hooks/sys'
export default {
  name: 'password',
  props: {},
  // eslint-disable-next-line no-unused-vars
  setup(props) {
    const store = useStore()
    const doLogout = useLogout(false)
    // 用户信息
    const userInfo = computed(() => {
      return store.state.userInfo
    })
    const illegal = str => {
      // eslint-disable-next-line no-useless-escape
      const filter = /[<>\'\"\ \	]/
      return filter.test(str)
    }
    const passwordFormRef = ref(null)
    const state = reactive({
      title: '修改密码',
      newPwdShow: false,
      agentPwdShow: false
    })
    const form = reactive({
      loading: false,
      oldpwd: '',
      newPwd: '',
      newPwdCheck: ''
    })
    const validForm = () => {
      return new Promise(resolve => {
        passwordFormRef.value.validate(valid => {
          if (valid) {
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    }
    const resetForm = () => {
      passwordFormRef.value.resetFields()
    }
    const handleCancel = () => {
      console.log('handleCancel')
      passwordFormRef.value.clearValidate()
      resetForm()
    }
    const checkPass = s => {
      const filter = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
      return filter.test(s)
    }
    const focusVerify = (name, type) => {
      console.log('????', name, type)
      state[name] = type
    }
    // 保存
    const handleSave = async () => {
      const valid = await validForm()
      if (!valid) return false
      if (userInfo.value?.uuid) {
        // 执行修改密码
        try {
          const params = {
            id: userInfo.value.uuid,
            oldPassword: form.oldpwd,
            newPassword: form.newPwd
          }
          form.loading = true
          const temp = await updatePassword(params)
          form.loading = false
          if (temp.isOk) {
            ElMessage.success('修改密码成功')
            // resetForm()
            doLogout()
          }
        } catch (error) {
          form.loading = false
          console.log('updatePassword', error)
        }
      }
    }
    // 校验必填及长度
    const validateOldPwd = (rule, value, callback) => {
      console.log('validateOldPwd')
      if (value === '') {
        callback(new Error('请输入原登录密码'))
      } else {
        callback()
      }
    }
    // 校验必填及长度
    const validatePwd = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新的登录密码'))
      } else if (value.length < 8 || value.length > 16) {
        callback(new Error('登录密码长度为8-18个字符'))
      } else if (value.indexOf(' ') > -1) {
        callback(new Error('不能使用空格、中文'))
      } else if (!checkPass(value)) {
        callback(new Error('至少含数字/字母/字符2种组合'))
      } else if (illegal(value)) {
        callback(new Error('不能含有非法字符'))
      } else if (form.newPwdCheck !== '') {
        passwordFormRef.value.validateField('newPwdCheck')
        callback()
      } else {
        callback()
      }
    }
    // 校验必填及长度及是否一致
    const validatePwdCheck = (rule, value, callback) => {
      console.log('validatePwdCheck')
      if (value === '') {
        callback(new Error('请输入新的登录密码'))
      } else if (value.length < 8 || value.length > 16) {
        callback(new Error('登录密码长度为8-18个字符'))
      } else if (value.indexOf(' ') > -1) {
        callback(new Error('不能使用空格、中文'))
      } else if (!checkPass(value)) {
        callback(new Error('至少含数字/字母/字符2种组合'))
      } else if (illegal(value)) {
        callback(new Error('不能含有非法字符'))
      } else if (value !== form.newPwd) {
        callback(new Error('两次输入新登录密码不一致!'))
      } else {
        callback()
      }
    }
    // 校验规则
    const rules = {
      oldpwd: [{ validator: validateOldPwd, trigger: 'blur' }],
      newPwd: [{ validator: validatePwd, trigger: 'blur' }],
      newPwdCheck: [{ validator: validatePwdCheck, trigger: 'blur' }]
    }
    onMounted(() => {})
    return {
      state,
      form,
      rules,
      validForm,
      passwordFormRef,
      handleCancel,
      handleSave,
      userInfo,
      validateOldPwd,
      validatePwd,
      validatePwdCheck,
      illegal,
      checkPass,
      focusVerify
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
